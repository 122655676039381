import ohbaby from './audio/okroshka.mp3'
import daynite from './audio/day&nite.mp3'
import cool from './audio/for_cool_and_lazy.mp3'
import zzz from './audio/zzz.mp3'
import thinking from './audio/thinking.mp3'
import mgl from './audio/mgl_mix.mp3'
import noservice from './audio/noservice.mp3'
import up from './audio/up.mp3'
import nargilia_1 from './audio/nargilia_1.mp3'
import nargilia_2 from './audio/nargilia_2.mp3'
import part_one from './audio/erotic_1.mp3'
import part_two from './audio/erotic_2.mp3'
import part_three from './audio/erotic_3.mp3'
import part_four from './audio/erotic_4.mp3'

import ohbaby_img from './audio/okroshka.jpg'
import daynite_img from './audio/day&nite.jpg'
import cool_img from './audio/for_cool_and_lazy.png'
import zzz_img from './audio/zzz.png'
import thinking_img from './audio/thinking.jpg'
import mgl_img from './audio/mgl_mix.jpg'
import noservice_img from './audio/noservice.jpg'
import up_img from './audio/up.jpg'
import nargilia_img from './audio/nargilia.jpg'
import part_one_img from './audio/part_1.jpg'
import part_two_img from './audio/part_2.jpg'
import part_three_img from './audio/part_3.jpg'
import part_four_img from './audio/part_4.jpg'

const tracks = [
    {
        title: "For Cool & Lazy Asses Mix (2012)",
        src: cool,
        thumbnail: cool_img,
        author: 'DJ ASTI'
    },
    {
        title: "Zzz Mix (2012)",
        src: zzz,
        thumbnail: zzz_img,
        author: 'DJ ASTI'
    },
    {
        title: "EROTIC HALL MIX Part 1 (2014)",
        src: part_one,
        thumbnail: part_one_img,
        author: 'DJ ASTI'
    },
    {
        title: "EROTIC HALL MIX Part 2 (2014)",
        src: part_two,
        thumbnail: part_two_img,
        author: 'DJ ASTI'
    },
    {
        title: "EROTIC HALL MIX Part 3 (2014)",
        src: part_three,
        thumbnail: part_three_img,
        author: 'DJ ASTI'
    },
    {
        title: "EROTIC HALL MIX Part 4 (2014)",
        src: part_four,
        thumbnail: part_four_img,
        author: 'DJ ASTI'
    },
    {
        title: "O'KROSHKA MIX (2012)",
        src: ohbaby,
        thumbnail: ohbaby_img,
        author: 'DJ ASTI'
    },
    {
        title: "DAY & NITE Global Heart Promo Mix (2013)",
        src: daynite,
        thumbnail: daynite_img,
        author: 'DJ ASTI'
    },
    {
        title: "Thinking About You Mix (2016)",
        src: thinking,
        thumbnail: thinking_img,
        author: 'DJ ASTI'
    },
    {
        title: "MGL MIX (2018)",
        src: mgl,
        thumbnail: mgl_img,
        author: 'DJ ASTI'
    },
    {
        title: "NoService Mix (2017)",
        src: noservice,
        thumbnail: noservice_img,
        author: 'DJ ASTI'
    },
    {
        title: "Up To The Sky Mix",
        src: up,
        thumbnail: up_img,
        author: 'DJ ASTI'
    },
    {
        title: "Nargilia Mix Part 1 (2015)",
        src: nargilia_1,
        thumbnail: nargilia_img,
        author: 'DJ ASTI'
    },
    {
        title: "Nargilia Mix Part 2 (2015)",
        src: nargilia_2,
        thumbnail: nargilia_img,
        author: 'DJ ASTI'
    }
]

export default tracks;