import heroImg from "../../assets/images/dark_hero.avif";
import lightHeroImg from "../../assets/images/light-hero.avif";
import "./HomePage.css";

export default function HomePage({ mode }) {
  return (
    <>
      <img
        className="nav-hero"
        src={mode === "" ? heroImg : lightHeroImg}
        alt=""
      />
      <div className="container-card">
        <div className="card">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="card-inner">
            <p>
              Welcome to the captivating world of DJ ASTI, where the power of
              music meets the art of sound design.
            </p>
          </div>
        </div>
        <div className="card">
          <div class="circle-2"></div>
          <div class="circle-3"></div>
          <div class="card-inner">
            <p>
              As a professional, whose journey started in 2006 her expertise
              extends beyond the boundaries of conventional DJing, showcasing a
              boundless talent for sound design that brings a new dimension to
              every performance. Embracing a wide range of music styles, her
              sets are a harmonious fusion of genres, elevating the energy of
              any event to extraordinary heights.
            </p>
          </div>
        </div>
        <div className="card">
          <div class="circle-2"></div>
          <div class="card-inner">
            <p>
              Prepare to surrender to the allure of deep house's seductive
              embrace as it guides you through a realm where music and
              sensuality entwine in perfect harmony. Get ready to immerse
              yourself in an unforgettable experience that will leave you
              longing for more, long after the final note fades into the night.
              Welcome to a world where deep house meets sensuality – a journey
              you'll cherish forever.
            </p>
          </div>
        </div>
        <div className="card">
          <div class="circle-3"></div>
          <div class="circle"></div>
          <div class="circle-4"></div>
          <div class="card-inner">
            <p>
              In the symphony of existence, music emerges as an ethereal
              extension of nature's most profound elements. Just as the
              whispering breeze rustles through the leaves, and the gentle waves
              caress the shore, music too breathes life into the world around
              us. The rhythmic symmetries mirror the heartbeat of Mother Earth,
              while melodies soar like the songbirds in the skies, and harmonies
              converge like the dance of the stars above. Nature's essence flows
              seamlessly through the strings, keys, and voices of musicians, as
              they become vessels for the cosmic symphony. From the tranquil
              serenity of ambient sounds to the thunderous crescendos of
              orchestral power, music captures the very essence of nature's
              spirit and speaks to the depths of our souls.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
