import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";

export default function MusicPage({ mode }) {
  return (
    <>
      <div>
        <AudioPlayer />
      </div>
    </>
  );
}
