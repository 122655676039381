import "./ContactPage.css";

export default function ConatctPage({ mode }) {
  return (
    <>
      <div className="container-contact">
        <div class="card-contact">
          <div class="card__content"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="blob"></div>
          <div class="card__text">
            For information and inquiries please contact{" "}
            <a href="mailto:asti@asticodes.dev">asti@asticodes.dev</a>
          </div>
        </div>
      </div>
    </>
  );
}
