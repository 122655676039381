import './App.css';
import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import 'animate.css';
import NavBar from '../../components/NavBar/NavBar';
import HomePage from '../HomePage/HomePage';
import PhotosPage from '../PhotosPage/PhotosPage';
import ConatctPage from '../ContactPage/ContactPage';
import MusicPage from '../MusicPage/MusicPage';
import pictures from '../../data/pictures'


function App() {
  const [mode, setMode] = useState("");

  const toggleMode = () => {
    if (mode === "") {
      setMode("light");
    } else {
      setMode("");
    }
  };

  useEffect(() => {
    document.body.className = mode;
  }, [mode]);

  return (
    <div className={`${mode} container`}>
      <NavBar toggleMode={toggleMode} mode={mode}/>
      <Routes>
        <Route path="/" element={<HomePage mode={mode} />} />
        <Route path="/about" />
        <Route path="/contact"element={<ConatctPage mode={mode} />} />
        <Route path="/music" element={<MusicPage mode={mode} />} />
        <Route path="/photos" element={<PhotosPage mode={mode} pictures={pictures } />}  />
      </Routes>

    </div>
  );
}

export default App;
