import "./PhotosPage.css";

export default function PhotosPage({ mode, pictures }) {
  return (
    <>
      <div className="photos">
        {pictures.map((p) => (
          <img src={p.img} alt="" />
        ))}
      </div>
    </>
  );
}
