import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./NavBar.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import logoDark from "../../assets/images/1.png";
import logoLight from "../../assets/images/3.png";

library.add(faSun, faMoon);

export default function NavBar({ toggleMode, mode }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    } else {
      setIsMobileMenuOpen(true);
    }
  };

  const handleMobileMenuClick = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 800) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      <ul className="navbar-links">
        <button className="toggle-btn" onClick={toggleMode}>
          <FontAwesomeIcon icon={mode === "" ? faMoon : faSun} />
        </button>
        <li className="navbar-link">
          <Link to="/music">MUSIC</Link>
        </li>

        <li className="navbar-link logo">
          <Link to="/">
            <img
              className="logo-center"
              src={mode === "" ? logoDark : logoLight}
              alt=""
            />
          </Link>
        </li>
        <li className="navbar-link">
          <Link to="/photos">PHOTOS</Link>
        </li>
        <li className="navbar-link">
          <Link to="/contact">CONTACT</Link>
        </li>
      </ul>
      <div className="hamburger-ctr" onClick={handleHamburgerClick}>
        {isMobileMenuOpen ? (
          <FontAwesomeIcon icon={faXmark} className="hamburger-icon" />
        ) : (
          <FontAwesomeIcon icon={faBarsStaggered} className="hamburger-icon" />
        )}
      </div>
      <ul className={isMobileMenuOpen ? "mobile-links open" : "mobile-links"}>
        <button className="toggle-btn" onClick={toggleMode}>
          <FontAwesomeIcon icon={mode === "" ? faMoon : faSun} />
        </button>
        <li className="mobile-link">
          <Link to="/" onClick={handleMobileMenuClick}>
            HOME
          </Link>
        </li>

        <li className="mobile-link">
          <Link to="/music" onClick={handleMobileMenuClick}>
            MUSIC
          </Link>
        </li>
        <li className="mobile-link logo">
          <Link to="/">
            <img src={mode === "" ? logoDark : logoLight} alt="" />
          </Link>
        </li>
        <li className="mobile-link">
          <Link to="/photos" onClick={handleMobileMenuClick}>
            PHOTOS
          </Link>
        </li>
        <li className="mobile-link">
          <Link to="/contact" onClick={handleMobileMenuClick}>
            CONTACT
          </Link>
        </li>
      </ul>
    </>
  );
}
