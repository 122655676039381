import a1 from '../assets/images/pictures/1.jpg';
import a2 from '../assets/images/pictures/2.jpg';
import a3 from '../assets/images/pictures/3.jpg';
import a4 from '../assets/images/pictures/4.jpg';
import a5 from '../assets/images/pictures/5.jpg';
import a6 from '../assets/images/pictures/6.jpg';
import a7 from '../assets/images/pictures/7.jpg';
import a8 from '../assets/images/pictures/8.jpg';
import a9 from '../assets/images/pictures/9.jpg';
import a10 from '../assets/images/pictures/10.jpg';
import a11 from '../assets/images/pictures/11.jpg';
import a12 from '../assets/images/pictures/12.jpg';
import a13 from '../assets/images/pictures/13.jpg';
import a14 from '../assets/images/pictures/14.jpg';
import a15 from '../assets/images/pictures/15.jpg';
import a16 from '../assets/images/pictures/16.jpg';
import a17 from '../assets/images/pictures/17.jpg';
import a18 from '../assets/images/pictures/18.jpg';
import a19 from '../assets/images/pictures/19.jpg';
import a20 from '../assets/images/pictures/20.jpg';
import a21 from '../assets/images/pictures/21.jpg';
import a22 from '../assets/images/pictures/22.jpg';
import a23 from '../assets/images/pictures/23.jpg';
import a24 from '../assets/images/pictures/24.jpg';
import a25 from '../assets/images/pictures/25.jpg';
import a26 from '../assets/images/pictures/26.jpg';
import a27 from '../assets/images/pictures/27.jpg';
import a28 from '../assets/images/pictures/28.jpg';
import a29 from '../assets/images/pictures/29.jpg';
import a30 from '../assets/images/pictures/30.jpg';
import a31 from '../assets/images/pictures/31.jpg';
import a32 from '../assets/images/pictures/32.jpg';
import a33 from '../assets/images/pictures/33.jpg';
import a34 from '../assets/images/pictures/34.jpg';
import a35 from '../assets/images/pictures/35.jpg';
import a36 from '../assets/images/pictures/36.jpg';
import a37 from '../assets/images/pictures/37.jpg';
import a38 from '../assets/images/pictures/38.jpg';
import a39 from '../assets/images/pictures/39.jpg';
import a40 from '../assets/images/pictures/40.jpg';
import a41 from '../assets/images/pictures/41.jpg';
import a42 from '../assets/images/pictures/42.jpg';
import a43 from '../assets/images/pictures/43.jpg';
import a44 from '../assets/images/pictures/44.jpg';
import a45 from '../assets/images/pictures/45.jpg';
import a46 from '../assets/images/pictures/46.jpg';
import a47 from '../assets/images/pictures/47.jpg';
import a48 from '../assets/images/pictures/48.jpg';
import a49 from '../assets/images/pictures/49.jpg';
import a50 from '../assets/images/pictures/50.jpg';
import a51 from '../assets/images/pictures/51.jpg';
import a52 from '../assets/images/pictures/52.jpg';
import a53 from '../assets/images/pictures/53.jpg';
import a54 from '../assets/images/pictures/54.jpg';
import a55 from '../assets/images/pictures/55.jpg';
import a56 from '../assets/images/pictures/56.jpg';
import a57 from '../assets/images/pictures/57.jpg';
import a58 from '../assets/images/pictures/58.jpg';
import a59 from '../assets/images/pictures/59.jpg';
import a60 from '../assets/images/pictures/60.jpg';
import a61 from '../assets/images/pictures/61.jpg';
import a62 from '../assets/images/pictures/62.jpg';
import a63 from '../assets/images/pictures/63.jpg';
import a64 from '../assets/images/pictures/64.jpg';
import a65 from '../assets/images/pictures/65.jpg';
import a66 from '../assets/images/pictures/66.jpg';
import a67 from '../assets/images/pictures/67.jpg';
import a68 from '../assets/images/pictures/68.jpg';
import a69 from '../assets/images/pictures/69.jpg';
import a70 from '../assets/images/pictures/70.jpg';
import a71 from '../assets/images/pictures/71.jpg';
import a72 from '../assets/images/pictures/72.jpg';
import a73 from '../assets/images/pictures/73.jpg';
import a74 from '../assets/images/pictures/74.jpg';
import a75 from '../assets/images/pictures/75.jpg';
import a76 from '../assets/images/pictures/76.jpg';
import a77 from '../assets/images/pictures/77.jpg';
import a78 from '../assets/images/pictures/78.jpg';
import a79 from '../assets/images/pictures/79.jpg';
import a80 from '../assets/images/pictures/80.jpg';
import a81 from '../assets/images/pictures/81.jpg';
import a82 from '../assets/images/pictures/82.jpg';
import a83 from '../assets/images/pictures/83.jpg';
import a84 from '../assets/images/pictures/84.jpg';
import a85 from '../assets/images/pictures/85.jpg';
import a86 from '../assets/images/pictures/86.jpg';
import a87 from '../assets/images/pictures/87.jpg';
import a88 from '../assets/images/pictures/88.jpg';
import a89 from '../assets/images/pictures/89.jpg';
import a90 from '../assets/images/pictures/90.jpg';
import a91 from '../assets/images/pictures/91.jpg';
import a92 from '../assets/images/pictures/92.jpg';
import a93 from '../assets/images/pictures/93.jpg';
import a94 from '../assets/images/pictures/94.jpg';
import a95 from '../assets/images/pictures/95.jpg';
import a96 from '../assets/images/pictures/96.jpg';
import a97 from '../assets/images/pictures/97.jpg';
import a98 from '../assets/images/pictures/98.jpg';
import a99 from '../assets/images/pictures/99.jpg';
import a100 from '../assets/images/pictures/100.jpg';
import a101 from '../assets/images/pictures/101.jpg';
import a102 from '../assets/images/pictures/102.jpg';
import a103 from '../assets/images/pictures/103.jpg';
import a104 from '../assets/images/pictures/104.jpg';
import a105 from '../assets/images/pictures/105.jpg';
import a106 from '../assets/images/pictures/106.jpg';
import a107 from '../assets/images/pictures/107.jpg';
import a108 from '../assets/images/pictures/108.jpg';
import a109 from '../assets/images/pictures/109.jpg';
import a110 from '../assets/images/pictures/110.jpg';
import a111 from '../assets/images/pictures/111.jpg';
import a112 from '../assets/images/pictures/112.jpg';
import a113 from '../assets/images/pictures/113.jpg';
import a114 from '../assets/images/pictures/114.jpg';
import a115 from '../assets/images/pictures/115.jpg';
import a116 from '../assets/images/pictures/116.jpg';
import a117 from '../assets/images/pictures/117.jpg';
import a118 from '../assets/images/pictures/118.jpg';
import a119 from '../assets/images/pictures/119.jpg';
import a120 from '../assets/images/pictures/120.jpg';
import a121 from '../assets/images/pictures/121.jpg';
import a122 from '../assets/images/pictures/122.jpg';
import a123 from '../assets/images/pictures/123.jpg';
import a124 from '../assets/images/pictures/124.jpg';
import a125 from '../assets/images/pictures/125.jpg';
import a126 from '../assets/images/pictures/126.jpg';
import a127 from '../assets/images/pictures/127.jpg';
import a128 from '../assets/images/pictures/128.jpg';
import a129 from '../assets/images/pictures/129.jpg';
import a130 from '../assets/images/pictures/130.jpg';
import a131 from '../assets/images/pictures/131.jpg';
import a132 from '../assets/images/pictures/132.jpg';
import a133 from '../assets/images/pictures/133.jpg';
import a134 from '../assets/images/pictures/134.jpg';
import a135 from '../assets/images/pictures/135.jpg';
import a136 from '../assets/images/pictures/136.jpg';
import a137 from '../assets/images/pictures/137.jpg';
import a138 from '../assets/images/pictures/138.jpg';
import a139 from '../assets/images/pictures/139.jpg';
import a140 from '../assets/images/pictures/140.jpg';
import a141 from '../assets/images/pictures/141.jpg';
import a142 from '../assets/images/pictures/142.jpg';
import a143 from '../assets/images/pictures/143.jpg';
import a144 from '../assets/images/pictures/144.jpg';
import a145 from '../assets/images/pictures/145.jpg';
import a146 from '../assets/images/pictures/146.jpg';
import a147 from '../assets/images/pictures/147.jpg';
import a148 from '../assets/images/pictures/148.jpg';
import a149 from '../assets/images/pictures/149.jpg';
import a150 from '../assets/images/pictures/150.jpg';
import a151 from '../assets/images/pictures/151.jpg';
import a152 from '../assets/images/pictures/152.jpg';
import a153 from '../assets/images/pictures/153.jpg';
import a154 from '../assets/images/pictures/154.jpg';
import a155 from '../assets/images/pictures/155.jpg';
import a156 from '../assets/images/pictures/156.jpg';
import a157 from '../assets/images/pictures/157.jpg';
import a158 from '../assets/images/pictures/158.jpg';
import a159 from '../assets/images/pictures/159.jpg';
import a160 from '../assets/images/pictures/160.jpg';
import a161 from '../assets/images/pictures/161.jpg';
import a162 from '../assets/images/pictures/162.jpg';
import a163 from '../assets/images/pictures/163.jpg';
import a164 from '../assets/images/pictures/164.jpg';
import a165 from '../assets/images/pictures/165.jpg';
import a166 from '../assets/images/pictures/166.jpg';
import a167 from '../assets/images/pictures/167.jpg';
import a168 from '../assets/images/pictures/168.jpg';
import a169 from '../assets/images/pictures/169.jpg';
import a170 from '../assets/images/pictures/170.jpg';
import a171 from '../assets/images/pictures/171.jpg';
import a172 from '../assets/images/pictures/172.jpg';
import a173 from '../assets/images/pictures/173.jpg';
import a174 from '../assets/images/pictures/174.jpg';
import a175 from '../assets/images/pictures/175.jpg';
import a176 from '../assets/images/pictures/176.jpg';
import a177 from '../assets/images/pictures/177.jpg';
import a178 from '../assets/images/pictures/178.jpg';
import a179 from '../assets/images/pictures/179.jpg';
import a180 from '../assets/images/pictures/180.jpg';
import a181 from '../assets/images/pictures/181.jpg';
import a182 from '../assets/images/pictures/182.jpg';




const pictures = [
    {
        id: 1,
        img: a1,
    },
    {
        id: 2,
        img: a2,
    },{
        id: 3,
        img: a3,
    },{
        id: 4,
        img: a4,
    },{
        id: 5,
        img: a5,
    },{
        id: 6,
        img: a6,
    },{
        id: 7,
        img: a7,
    },{
        id: 8,
        img: a8,
    },{
        id: 9,
        img: a9,
    },{
        id: 10,
        img: a10,
    },{
        id: 11,
        img: a11,
    },{
        id: 12,
        img: a12,
    },{
        id: 13,
        img: a13,
    },{
        id: 14,
        img: a14,
    },{
        id: 15,
        img: a15,
    },{
        id: 16,
        img: a16,
    },{
        id: 17,
        img: a17,
    },{
        id: 18,
        img: a18,
    },{
        id: 19,
        img: a19,
    },{
        id: 20,
        img: a20,
    },{
        id: 21,
        img: a21,
    },{
        id: 22,
        img: a22,
    },{
        id: 23,
        img: a23,
    },{
        id: 24,
        img: a24,
    },{
        id: 25,
        img: a25,
    },{
        id: 26,
        img: a26,
    },{
        id: 27,
        img: a27,
    },{
        id: 28,
        img: a28,
    },{
        id: 29,
        img: a29,
    },{
        id: 30,
        img: a30,
    },{
        id: 31,
        img: a31,
    },{
        id: 32,
        img: a32,
    },{
        id: 33,
        img: a33,
    },{
        id: 34,
        img: a34,
    },{
        id: 35,
        img: a35,
    },{
        id: 36,
        img: a36,
    },{
        id: 37,
        img: a37,
    },{
        id: 38,
        img: a38,
    },{
        id: 39,
        img: a39,
    },{
        id: 40,
        img: a40,
    },{
        id: 41,
        img: a41,
    },{
        id: 42,
        img: a42,
    },
    {
        id: 43,
        img: a43,
    },
    {
        id: 44,
        img: a44,
    },
    {
        id: 45,
        img: a45,
    },
    {
        id: 46,
        img: a46,
    },
    {
        id: 47,
        img: a47,
    },
    {
        id: 48,
        img: a48,
    },
    {
        id: 49,
        img: a49,
    },
    {
        id: 50,
        img: a50,
    },
    {
        id: 51,
        img: a51,
    },
    {
        id: 52,
        img: a52,
    },
    {
        id: 53,
        img: a53,
    },
    {
        id: 54,
        img: a54,
    },
    {
        id: 55,
        img: a55,
    },
    {
        id: 56,
        img: a56,
    },
    {
        id: 57,
        img: a57,
    },
    {
        id: 58,
        img: a58,
    },
    {
        id: 59,
        img: a59,
    },
    {
        id: 60,
        img: a60,
    },
    {
        id: 61,
        img: a61,
    },
    {
        id: 62,
        img: a62,
    },
    {
        id: 63,
        img: a63,
    },
    {
        id: 64,
        img: a64,
    },
    {
        id: 65,
        img: a65,
    },
    {
        id: 66,
        img: a66,
    },
    {
        id: 67,
        img: a67,
    },
    {
        id: 68,
        img: a68,
    },
    {
        id: 69,
        img: a69,
    },
    {
        id: 70,
        img: a70,
    },
    {
        id: 71,
        img: a71
      },
      {
        id: 72,
        img: a72
      },
      {
        id: 73,
        img: a73
      },
      {
        id: 74,
        img: a74
      },
      {
        id: 75,
        img: a75
      },
      {
        id: 76,
        img: a76
      },
      {
        id: 77,
        img: a77
      },
      {
        id: 78,
        img: a78
      },
      {
        id: 79,
        img: a79
      },
      {
        id: 80,
        img: a80
      },
      {
        id: 81,
        img: a81
      },
      {
        id: 82,
        img: a82
      },
      {
        id: 83,
        img: a83
      },
      {
        id: 84,
        img: a84
      },
      {
        id: 85,
        img: a85
      },
      {
        id: 86,
        img: a86
      },
      {
        id: 87,
        img: a87
      },
      {
        id: 88,
        img: a88
      },
      {
        id: 89,
        img: a89
      },
      {
        id: 90,
        img: a90
      },
      {
        id: 91,
        img: a91
      },
      {
        id: 92,
        img: a92
      },
      {
        id: 93,
        img: a93
      },
      {
        id: 94,
        img: a94
      },
      {
        id: 95,
        img: a95
      },
      {
        id: 96,
        img: a96
      },
      {
        id: 97,
        img: a97
      },
      {
        id: 98,
        img: a98
      },
      {
        id: 99,
        img: a99
      },
      {
        id: 100,
        img: a100
      },
      {
        id: 101,
        img: a101
      },
      {
        id: 102,
        img: a102
      },
      {
        id: 103,
        img: a103
      },
      {
        id: 104,
        img: a104
      },
      {
        id: 105,
        img: a105
      },
      {
        id: 106,
        img: a106
      },
      {
        id: 107,
        img: a107
      },
      {
        id: 108,
        img: a108
      },
      {
        id: 109,
        img: a109
      },
      {
        id: 110,
        img: a110
      },
      {
        id: 111,
        img: a111
      },
      {
        id: 112,
        img: a112
      },
      {
        id: 113,
        img: a113
      },
      {
        id: 114,
        img: a114
      },
      {
        id: 115,
        img: a115
      },
      {
        id: 116,
        img: a116
      },
      {
        id: 117,
        img: a117
      },
      {
        id: 118,
        img: a118
      },
      {
        id: 119,
        img: a119
      },
      {
        id: 120,
        img: a120
      },
      {
        id: 121,
        img: a121
      },
      {
        id: 122,
        img: a122
      },
      {
        id: 123,
        img: a123
      },
      {
        id: 124,
        img: a124
      },
      {
        id: 125,
        img: a125
      },
      {
        id: 126,
        img: a126
      },
      {
        id: 127,
        img: a127
      },
      {
        id: 128,
        img: a128
      },
      {
        id: 129,
        img: a129
      },
      {
        id: 130,
        img: a130
      },
      {
        id: 131,
        img: a131
      },
      {
        id: 132,
        img: a132
      },
      {
        id: 133,
        img: a133
      },
      {
        id: 134,
        img: a134
      },
      {
        id: 135,
        img: a135
      },
      {
        id: 136,
        img: a136
      },
      {
        id: 137,
        img: a137
      },
      {
        id: 138,
        img: a138
      },
      {
        id: 139,
        img: a139
      },
      {
        id: 140,
        img: a140
      },
      {
        id: 141,
        img: a141
      },
      {
        id: 142,
        img: a142
      },
      {
        id: 143,
        img: a143
      },
      {
        id: 144,
        img: a144
      },
      {
        id: 145,
        img: a145
      },
      {
        id: 146,
        img: a146
      },
      {
        id: 147,
        img: a147
      },
      {
        id: 148,
        img: a148
      },
      {
        id: 149,
        img: a149
      },
      {
        id: 150,
        img: a150
      },
      {
        id: 151,
        img: a151
      },
      {
        id: 152,
        img: a152
      },
      {
        id: 153,
        img: a153
      },
      {
        id: 154,
        img: a154
      },
      {
        id: 155,
        img: a155
      },
      {
        id: 156,
        img: a156
      },
      {
        id: 157,
        img: a157
      },
      {
        id: 158,
        img: a158
      },
      {
        id: 159,
        img: a159
      },
      {
        id: 160,
        img: a160
      },
      {
        id: 161,
        img: a161
      },
      {
        id: 162,
        img: a162
      },
      {
        id: 163,
        img: a163
      },
      {
        id: 164,
        img: a164
      },
      {
        id: 165,
        img: a165
      },
      {
        id: 166,
        img: a166
      },
      {
        id: 167,
        img: a167
      },
      {
        id: 168,
        img: a168
      },
      {
        id: 169,
        img: a169
      },
      {
        id: 170,
        img: a170
      },
      {
        id: 171,
        img: a171
      },
      {
        id: 172,
        img: a172
      },
      {
        id: 173,
        img: a173
      },
      {
        id: 174,
        img: a174
      },
      {
        id: 175,
        img: a175
      },
      {
        id: 176,
        img: a176
      },
      {
        id: 177,
        img: a177
      },
      {
        id: 178,
        img: a178
      },
      {
        id: 179,
        img: a179
      },
      {
        id: 180,
        img: a180
      },
      {
        id: 181,
        img: a181
      },



    {
        id: 182,
        img: a182,
    }
]
export default pictures;